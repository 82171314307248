/* eslint-disable eqeqeq, no-console, no-use-before-define, new-cap */
import * as CryptoJS from 'crypto-js';
import _, {isArray, isObject} from 'lodash';

import Auth from './auth.service';
import {getAllCountryNames, getApiUrlsByAppEnv, getS3Domain, getWebsiteApi, getS3RootFolderName} from '../constants/apiUrls';
import {aesSecretIv} from '../constants/defaultValues';

export default {
    encrypt(data) {
        return btoa(data);
    },
    decrypt(data) {
        return atob(data);
    },
    getAesSecretKey(){
        return CryptoJS.SHA256(getS3RootFolderName()).toString();
    },
    aesEncrypt(data){
        const aesEncryptionKey = this.getAesSecretKey();
        // encodedWord Array object
        const encodedData = CryptoJS.enc.Utf8.parse(data); 
        
        const Utf8 = CryptoJS.enc.Utf8;
        const key = CryptoJS.SHA256(aesEncryptionKey).toString(CryptoJS.enc.Hex).substring(0, 32);
        const iv = CryptoJS.SHA256(aesSecretIv).toString(CryptoJS.enc.Hex).substring(0, 16);

        const encrypted = CryptoJS.AES.encrypt(encodedData, Utf8.parse(key), {iv: Utf8.parse(iv)});
        // const encryptedCP = CryptoJS.AES.encrypt(data, key, {iv: iv});
        // const decryptedWA = CryptoJS.AES.decrypt(encrypted, Utf8.parse(key), {iv: Utf8.parse(iv)});
        // console.log('Test1:', encrypted.toString());
        // console.log('Test2:', decryptedWA.toString(CryptoJS.enc.Utf8));

        return this.encrypt(encrypted.toString());
    },
    aesDecrypt(encryptedData){
        const aesEncryptionKey = this.getAesSecretKey();
        const Utf8 = CryptoJS.enc.Utf8;
        const key = CryptoJS.SHA256(aesEncryptionKey).toString(CryptoJS.enc.Hex).substring(0, 32);
        const iv = CryptoJS.SHA256(aesSecretIv).toString(CryptoJS.enc.Hex).substring(0, 16);
        const encrypt = CryptoJS.enc.Base64.parse(encryptedData).toString(CryptoJS.enc.Utf8);
        return decodeURIComponent(CryptoJS.AES.decrypt(encrypt, Utf8.parse(key), {iv: Utf8.parse(iv)}).toString(Utf8));
    },
    makeName(Name) {
        return Name.replace(/[^a-z0-9\s]/gi, '').replace(/[_\s]/g, '_').replace(/-+/g, '_').toLowerCase();
    },
    makeHyphenName(Name) {
        return Name.replace(/[^a-z0-9\s]/gi, '-').replace(/[-\s]/g, '-').replace(/-+/g, '-').toLowerCase();
    },
    trim(data) {
        return data.replace(/^\s+|\s+$/g, '');
    },
    ltrim(data) {
        return data.replace(/^\s+/, '');
    },
    rtrim(data) {
        return data.replace(/\s+$/, '');
    },
    lRtrim(data) {
        data = data.replace(/^\s+/, '');
        return data.replace(/\s+$/, '');
    },
    clean(data) {
        if (data === null) {
            data = '';
        } else if (typeof data === 'undefined') {
            data = '';
        } else {
            data = data.toString().replace(/^\s+/, '');
        }

        return data;
    },
    getFormatDate(DateStr) {
        const DateArr = DateStr.split('-');
        return new Date(DateArr[2] + '-' + DateArr[1] + '-' + DateArr[0]);
    },
    getOptions(data) {
        const Options = [];

        if (data.length > 0) {
            data.map((Row, Index) =>
                Options.push({
                    label: Row.name,
                    value: Row.id,
                    key: Index
                })
            )
        }

        return Options;
    },
    getEncryptedJson(data) {
        return this.encrypt(encodeURIComponent(JSON.stringify(data)));
    },
    getDecryptedJson(data) {
        return JSON.parse(decodeURIComponent(this.decrypt(data)));
    },
    isEmpty(value) {
        return typeof value == 'string' && !value.trim() || typeof value == 'undefined' || value === null;
    },
    pluck(array, key) {
        return _.isArray(array) && _.map(array, key);
    },
    toStr(value) {
        return typeof value == 'undefined' || value === null || value.toString();
    },
    toCapitaliseEachWord(string) {
        return string
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    },
    removeKeys(values, keys) {
        /* @author Vikrant -  this function will take values as array or object and remove unwanted keys and return array or object as per given type */
        if (typeof values === 'object') {
            if (isArray(values)) {
                return _.filter(values, function (item) {
                    return _.map(keys, function (key) {
                        delete item[key];
                    });
                });
            }

            return _.omit(values, keys);
        }
    },
    getPasskeysData(values, keys) {
        /* @author Vikrant -  this function will take values as array or object and filter get only given keys value and return array or object as per given type */
        if (typeof values === 'object') {
            if (isArray(values)) {
                const newarr = [];
                _.filter(values, function (item) {
                    const newobj = {};
                    Object.entries(item).filter((row) => {
                        if (keys.includes(row[0])) {
                            newobj[row[0]] = row[1];
                        }

                        return row;
                    });
                    newarr.push(newobj);
                });
                return newarr;
            }

            const newobj = {};
            Object.entries(values).filter((row) => {
                if (keys.includes(row[0])) {
                    newobj[row[0]] = row[1];
                }

                return row;
            });
            return newobj;
        }
    },
    jsonToQueryString(values) {
        // return Object.keys(values).map(key => key + '=' + values[key]).join('&');
        return Object.keys(values).map(key => key + '=' + (isArray(values[key]) || isObject(values[key]) ? JSON.stringify(values[key]) : values[key])).join('&');
    },
    objectToQueryString(values) {
        return new URLSearchParams(values).toString();
    },
    selectedCountry() {
        const countryName = Auth.userInfo?.pimSelectedCountry ? JSON.parse(Auth.userInfo?.pimSelectedCountry)['country'] : '';

        if (countryName) {
            const selectedCountryJson = Object.entries(getAllCountryNames()).filter((row) => {
                return row[1] === countryName;
            });
            selectedCountryJson[0][2] = getApiUrlsByAppEnv()[selectedCountryJson[0][0]];
            return {
                'short_name': selectedCountryJson[0][0],
                'long_name': selectedCountryJson[0][1],
                'url': selectedCountryJson[0][2]
            }
        }

        return {};

    },
    getSubModulePermissionJson(subModuleId, role) {
        let permissionJson = {};
        Object.entries(role.permissions).forEach((row) => {
            const subModuleJson = row[1]['sub_modules'][subModuleId];

            if (subModuleJson) {
                permissionJson = subModuleJson;
            }
        })
        return permissionJson;
    },
    getAuthUserRolePermissionObject() {
        return JSON.parse(JSON.parse(Auth.userInfo.AdminAuthData).UserData?.role_permissions)
    },
    getCurrentCountryAccess() {
        const shortCountryName = this.selectedCountry()?.short_name;
        const countryAccessArr = JSON.parse(JSON.parse(Auth.userInfo.auth_user)?.more_details).country_access;
        const filterArr = countryAccessArr.filter((row) => row.country === shortCountryName);
        return filterArr?.[0];
    },
    setCurrentPageAccessObject(subModuleJson) {
        localStorage.setItem('current_page_access', JSON.stringify(subModuleJson));
    },
    setDataInLocalStorage(key, subModuleJson) {
        localStorage.setItem(key, JSON.stringify(subModuleJson));
    },
    getCurrentPageAccessObject() {
        return Auth.userInfo?.current_page_access && JSON.parse(Auth.userInfo?.current_page_access)
    },
    isRoleSuperAdmin() {
        return JSON.parse(Auth.userInfo?.auth_user)?.role === 7
    },
    getAuthCountries() {
        const moreDetails = Auth.userInfo?.auth_user ? JSON.parse(Auth.userInfo?.auth_user)?.more_details : [];
        return moreDetails ? (JSON.parse(moreDetails)?.country_access || []).filter((row) => row?.is_accessible ?? true) : [];
    },
    getAuthCountryShortNames() {
        return _.map(this.getAuthCountries(), 'country');
    },

    getAllCountriesWithApis() {
        const clonedObject = {...getAllCountryNames()};
        const formattedCountriesWithApis = [];
        Object.entries(clonedObject).forEach((row) => {
            formattedCountriesWithApis[row[0]] = {
                'short_name': row[0],
                'long_name': row[1],
                'url': getApiUrlsByAppEnv()[row[0]]
            }
        });
        return formattedCountriesWithApis;
    },
    getLoggedInUserCountryAccessWithApis() {
        const clonedObject = {...getAllCountryNames()};
        const formattedCountriesWithApis = [];
        Object.entries(clonedObject).forEach((row) => {
            formattedCountriesWithApis[row[0]] = {
                'short_name': row[0],
                'long_name': row[1],
                'url': getApiUrlsByAppEnv()[row[0]]
            }
        });
        const countriesAccess = Auth.userInfo?.auth_user ? JSON.parse(JSON.parse(Auth.userInfo?.auth_user)?.more_details)?.country_access : [];
        const customCountriesAccess = countriesAccess.map(row => {
            return row = {...row, ...formattedCountriesWithApis[row?.country]};
        })
        return customCountriesAccess;
    },
    getS3Link(params) {
        return getS3Domain() + params;
    },
    getS3BaseUrl(params = '') {
        return getS3Domain() + params;
    },
    adminAuthUser() {
        return Auth.userInfo?.AdminAuthData ? JSON.parse(Auth.userInfo?.AdminAuthData)?.UserData : {};
    },
    /**
     * Returns number of minutes for the idle timer, after which
     * the application logs out the user if the user is idle.
     *
     * @returns {number}
     */
    getIdleStateTime() {
        return parseInt(localStorage?.AdminAuthData && JSON.parse(localStorage.AdminAuthData)?.pcadmin_idle_time
            ? JSON.parse(localStorage.AdminAuthData)?.pcadmin_idle_time :
            20);
    },
    formatAndRemoveDuplicatePinCodeAddress(customKey1, customKey2, data, fieldName, value, pincode = null) {
        const filterBy = value ? value : pincode;
        let firstKey = '';
        let secondKey = '';
        let stateKey = fieldName;
        let filterByName = '';

        if (fieldName === 'pincode') {
            stateKey = 'states';
            firstKey = 'state_id';
            filterByName = 'pincode';
            secondKey = 'state_name';
        } else if (fieldName === 'states') {
            stateKey = 'provinces';
            filterByName = 'state_id';
            firstKey = 'province_id';
            secondKey = 'province_name';
        } else if (fieldName === 'provinces') {
            stateKey = 'cities';
            filterByName = 'province_id';
            firstKey = 'city';
            secondKey = 'city';
        }

        /* develop by vikraant Vishwakarma it is use in place of pincode selector */
        const filteredAddress = [];
        filterBy && (data || []).forEach(row => {
            if (pincode) {
                filteredAddress.push({[firstKey]: row[firstKey], [secondKey]: row[secondKey], 'value': row[secondKey]})
            } else if (row[filterByName] == filterBy) {
                filteredAddress.push({[firstKey]: row[firstKey], [secondKey]: row[secondKey], 'value': row[secondKey]})
            }
        });
        const uniqueAddress = [...new Map(filteredAddress.map(item => [item[firstKey], item] && [item[secondKey], item])).values()]
            .map(row => {
                if (row[firstKey]) {
                    row[customKey1] = row[firstKey];
                    row[customKey2] = row[secondKey];
                }

                delete row[firstKey];
                delete row[secondKey];
                return row;
            }).filter(row => row.value);
        return [stateKey, uniqueAddress];
    },
    getExcelCompatibleDateFormat(dateFormat = null) {
        if (dateFormat === 'MM-DD-YYYY') return 'm-d-yyy';
        if (dateFormat === 'YYYY-MM-DD') return 'yyyy-mm-dd';
        return 'dd-mm-yyyy';
    },
    getExcelCompatibleDateTimeFormat(dateFormat = null) {
        if (dateFormat === 'MM-DD-YYYY') return 'm-d-yyy hh:mm:ss';
        if (dateFormat === 'YYYY-MM-DD') return 'yyyy-mm-dd hh:mm:ss';
        if (dateFormat === 'YYYY-MM-DD HH:MM') return 'yyyy-mm-dd hh:mm';
        return 'dd-mm-yyyy hh:mm:ss';
    },
    getExcelCompatibleIntegerFormat() {
        return '0';
    },
    getExcelCompatibleDecimalFormat() {
        return '0.00';
    },
    getExcelDateFormatLabel() {
        return ' (dd-mm-yyyy)'
    },
    getExcelDateTimeFormatLabel() {
        return ' (dd-mm-yyyy hh:mm:ss)'
    },
    getSelectedCountryWebsiteBaseUrl() {
        return getWebsiteApi(this.selectedCountry().short_name);
    },
    isArrObjectEmpty(value) {
        // return typeof value == 'string' && !value.trim() || typeof value == 'undefined' || value === null;
        // return typeof value == 'object' && !value.trim() || typeof value == 'undefined' || value === null;
        return Object.keys(value).length === 0;
    },
    isObject(object) {
        return object != null && typeof object === 'object';
    },
    deepEqual(object1, object2) {
        const keys1 = Object.keys(object1);
        const keys2 = this.isObject(object2) ? Object.keys(object2) : [];

        if (keys1.length !== keys2.length) {
            return false;
        }

        for (const key of keys1) {
            const val1 = object1[key];
            const val2 = object2[key];
            const areObjects = this.isObject(val1) && this.isObject(val2);

            if (
                areObjects && !this.deepEqual(val1, val2) ||
                !areObjects && val1 !== val2
            ) {
                return false;
            }
        }

        return true;
    },
    changeDateFormat(inputDate, format) {
        // parse the input date
        const date = new Date(inputDate);

        // extract the parts of the date
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        // replace the month

        format = format.replace('MM', month.toString().padStart(2, '0'));
        // replace the year

        if (format.indexOf('yyyy') > -1) {
            format = format.replace('yyyy', year.toString());
        } else if (format.indexOf('yy') > -1) {
            format = format.replace('yy', year.toString().substr(2, 2));
        }

        // replace the day
        format = format.replace('dd', day.toString().padStart(2, '0'));

        return format;
    },
    instanceAuthUser() {
        return Auth.userInfo?.country_admin_auth_data ? JSON.parse(Auth.userInfo?.country_admin_auth_data)?.UserData : {};
    },
    cloneData(data) {
        return JSON.parse(JSON.stringify(data));
    }

}
