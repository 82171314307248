const isUndefined = (value) => value === undefined;

const isNull = (value) => value === null;

const isBoolean = (value) => typeof value === 'boolean';

const isObject = (value) => value === Object(value);

const isArray = (value) => Array.isArray(value);

const isDate = (value) => value instanceof Date;

const isMomentDate = (value) => value && typeof value.isMoment === 'function' && value.isMoment();

const isBlob = (value) =>
    value &&
    typeof value.size === 'number' &&
    typeof value.type === 'string' &&
    typeof value.slice === 'function';

const isFile = (value) =>
    isBlob(value) &&
    typeof value.name === 'string' &&
    (typeof value.lastModifiedDate === 'object' ||
        typeof value.lastModified === 'number');

/**
 * This function converts a JavaScript object to FormData object recursively.
 *
 * @param obj
 * @param config
 * @param fd
 * @param pre
 * @return FormData
 */
export const serialize = (obj, config, fd, pre) => {
    config = config || {};

    config.indices = isUndefined(config.indices) ? true : config.indices;

    config.nullsAsUndefineds = isUndefined(config.nullsAsUndefineds) ? false : config.nullsAsUndefineds;

    config.booleansAsIntegers = isUndefined(config.booleansAsIntegers) ? false : config.booleansAsIntegers;

    fd = fd || new FormData();

    if (isUndefined(obj)) {
        return fd;

    } else if (isNull(obj)) {
        if (!config.nullsAsUndefineds) {
            fd.append(pre, '');
        }

    } else if (isBoolean(obj)) {
        if (config.booleansAsIntegers) {
            fd.append(pre, obj ? 1 : 0);
        } else {
            fd.append(pre, obj);
        }

    } else if (isArray(obj)) {
        if (obj.length) {
            obj.forEach((value, index) => {
                const key = pre + '[' + (config.indices ? index : '') + ']';

                serialize(value, config, fd, key);
            });
        }

    } else if (isDate(obj)) {
        fd.append(pre, obj.toISOString());

    } else if (isMomentDate(obj)) {
        fd.append(pre, obj.format('YYYY-MM-DD HH:mm:ss'));

    } else if (isObject(obj) && !isFile(obj) && !isBlob(obj)) {
        Object.keys(obj).forEach((prop) => {
            const value = obj[prop];

            if (isArray(value)) {
                while (prop.length > 2 && prop.lastIndexOf('[]') === prop.length - 2) {
                    prop = prop.substring(0, prop.length - 2);
                }
            }

            const key = pre ? pre + '[' + prop + ']' : prop;

            serialize(value, config, fd, key);
        });

    } else {
        fd.append(pre, obj);

    }

    return fd;
};

/**
 * Generate query string for GET requests from provided data.
 *
 * @param data
 * @returns {string}
 */
export const encodeQuery = (data) => {
    let query = ''

    for (const d in data) {
        query += encodeURIComponent(d) + '=' + encodeURIComponent(data[d]) + '&'
    }

    return query.slice(0, -1);
};

export const downloadBlobData = (fileName, blobData) => {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(new Blob([blobData]));
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
};
