import {all} from 'redux-saga/effects';

import authSagas from './auth/saga';
import chatSagas from './chat/saga';
import surveyDetailSagas from './surveyDetail/saga';
import surveyListSagas from './surveyList/saga';
import todoSagas from './todo/saga';

// eslint-disable-next-line no-unused-vars
export default function* rootSaga(getState) {
    yield all([
        authSagas(),
        todoSagas(),
        chatSagas(),
        surveyListSagas(),
        surveyDetailSagas()
    ]);
}
