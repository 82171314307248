import {
    isMultiColorActive,
    defaultColor,
    isDarkSwitchActive
} from "./constants/defaultValues";
import { getCurrentColor, setCurrentColor } from "./helpers/Utils";

import "./assets/css/vendor/bootstrap.min.css";
import "./assets/css/vendor/bootstrap.rtl.only.min.css";
import "react-perfect-scrollbar/dist/css/styles.css";

const color = (isMultiColorActive || isDarkSwitchActive) ? getCurrentColor() : defaultColor;
setCurrentColor(color);

const render = () => {
    import(`./assets/css/sass/themes/gogo.${color}.scss`).then(() => {
        require("./AppRenderer");
    });
};
render();
