import {combineReducers} from 'redux';

import authUser from './auth/reducer';
import chatApp from './chat/reducer';
import menu from './menu/reducer';
import settings from './settings/reducer';
import surveyDetailApp from './surveyDetail/reducer';
import surveyListApp from './surveyList/reducer';
import todoApp from './todo/reducer';

const reducers = combineReducers({
    menu,
    settings,
    authUser,
    todoApp,
    chatApp,
    surveyListApp,
    surveyDetailApp
});

export default reducers;
