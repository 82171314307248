import {setCurrentLanguage} from '../../helpers/Utils';
import {CHANGE_LOCALE, CHANGE_COUNTRY, CHANGE_COUNTRY_COMPLETE} from '../actions';

export const changeLocale = (locale) => {
    setCurrentLanguage(locale);
    return {
        type: CHANGE_LOCALE,
        payload: locale
    };
};

export const changeCountry = (countryUserId, countryShortName, history) => {
    return {
        type: CHANGE_COUNTRY,
        payload: {countryUserId, countryShortName, history}
    };
};

export const changeCountryComplete = (success = true, history) => {
    return {
        type: CHANGE_COUNTRY_COMPLETE,
        payload: {success, history}
    };
};
