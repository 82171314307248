import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGOUT_USER,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    LOGIN_USER_ERROR,
    REGISTER_USER_ERROR,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_ERROR,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR, GOOGLE_AUTH, GOOGLE_AUTH_ERROR, GOOGLE_AUTH_SUCCESS, COUNTRY_WISE_ERROR, RESET_ERRORS
} from '../actions';

export const loginUser = (user, history) => ({
    type: LOGIN_USER,
    payload: {user, history}
});

export const loginUserSuccess = (user) => ({
    type: LOGIN_USER_SUCCESS,
    payload: user
});

export const loginUserError = (message) => ({
    type: LOGIN_USER_ERROR,
    payload: {message}
});

export const forgotPassword = (forgotUserMail, history) => ({
    type: FORGOT_PASSWORD,
    payload: {forgotUserMail, history}
});

export const forgotPasswordSuccess = (message) => ({
    type: FORGOT_PASSWORD_SUCCESS,
    payload: message
});

export const forgotPasswordError = (message) => ({
    type: FORGOT_PASSWORD_ERROR,
    payload: {message}
});

export const resetPassword = ({resetPasswordCode, resetToken, newPassword, countryUrls, history}) => ({
    type: RESET_PASSWORD,
    payload: {resetPasswordCode, resetToken, newPassword, countryUrls, history}
});

export const resetPasswordSuccess = (message) => ({
    type: RESET_PASSWORD_SUCCESS,
    payload: message
});

export const resetPasswordError = (message) => ({
    type: RESET_PASSWORD_ERROR,
    payload: {message}
});

export const registerUser = (user, history) => ({
    type: REGISTER_USER,
    payload: {user, history}
});

export const registerUserSuccess = (user) => ({
    type: REGISTER_USER_SUCCESS,
    payload: user
});

export const registerUserError = (message) => ({
    type: REGISTER_USER_ERROR,
    payload: {message}
});

export const logoutUser = (history) => ({
    type: LOGOUT_USER,
    payload: {history}
});

export const googleAuth = (formData, history) => ({
    type: GOOGLE_AUTH,
    payload: {formData, history}
});

export const googleAuthError = (message) => ({
    type: GOOGLE_AUTH_ERROR,
    payload: {message}
});

export const googleAuthSuccess = (message) => ({
    type: GOOGLE_AUTH_SUCCESS,
    payload: {message}
});

export const countryWiseError = (message)=>({
    type: COUNTRY_WISE_ERROR,
    payload: {message}
});

export const resetErrors = () => ({
    type: RESET_ERRORS,
    payload: {}
})
