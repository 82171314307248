import {isAuthGuardActive, currentUser} from '../../constants/defaultValues';
import {getCurrentUser} from '../../helpers/Utils';
import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_ERROR,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_ERROR,
    LOGOUT_USER,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_ERROR,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR, GOOGLE_AUTH, GOOGLE_AUTH_ERROR, GOOGLE_AUTH_SUCCESS, COUNTRY_WISE_ERROR, RESET_ERRORS
} from '../actions';

const INIT_STATE = {
    currentUser: isAuthGuardActive ? currentUser : getCurrentUser(),
    forgotUserMail: '',
    newPassword: '',
    resetPasswordCode: '',
    loading: false,
    error: '',
    countryErrors: {}
};

export default (state = INIT_STATE, action) => {

    switch (action.type) {
        case LOGIN_USER:
            return {...state, loading: true, error: ''};
        case LOGIN_USER_SUCCESS:
            return {...state, loading: true, currentUser: action.payload, error: ''};
        case LOGIN_USER_ERROR:
            return {
                ...state,
                loading: false,
                currentUser: null,
                error: action.payload.message
            };
        case FORGOT_PASSWORD:
            return {...state, loading: true, error: ''};
        case FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                forgotUserMail: action.payload,
                error: ''
            };
        case FORGOT_PASSWORD_ERROR:
            return {
                ...state,
                loading: false,
                forgotUserMail: '',
                error: action.payload.message
            };
        case RESET_PASSWORD:
            return {...state, loading: true, error: ''};
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                newPassword: '',
                resetPasswordCode: '',
                error: '',
                message: action.payload
            };
        case RESET_PASSWORD_ERROR:
            return {
                ...state,
                loading: false,
                newPassword: '',
                resetPasswordCode: '',
                error: action.payload.message
            };
        case REGISTER_USER:
            return {...state, loading: true, error: ''};
        case REGISTER_USER_SUCCESS:
            return {...state, loading: false, currentUser: action.payload, error: ''};
        case REGISTER_USER_ERROR:
            return {
                ...state,
                loading: false,
                currentUser: null,
                error: action.payload.message
            };
        case LOGOUT_USER:
            return {...state, currentUser: null, error: ''};
        case GOOGLE_AUTH:
            return {...state, loading: true, error: ''};
        case GOOGLE_AUTH_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.message
            };
        case GOOGLE_AUTH_SUCCESS:
            return {
                ...state,
                loading: false,
                error: ''
            };
        case COUNTRY_WISE_ERROR:
            return {
                ...state,
                loading: false,
                countryErrors: action.payload.message
            };
        case RESET_ERRORS:
            return {
                ...state,
                ...INIT_STATE
            };
        default:
            return {...state};
    }
};
