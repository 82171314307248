import {UserRole} from '../helpers/authHelper';

if (!('process' in window)) {
    // @ts-ignore
    window.process = {}
}

export const appVersion = process?.env?.REACT_APP_BUILD_TIMESTAMP;

/* 
 * Menu Types: "menu-default", "menu-sub-hidden", "menu-hidden"
 */
export const defaultMenuType = 'menu-sub-hidden';

export const subHiddenBreakpoint = 1440;

export const menuHiddenBreakpoint = 768;

export const defaultLocale = 'en';

export const localeOptions = [
    {id: 'en', name: 'English - LTR', direction: 'ltr'}
];

export const currentUser = {
    id: null,
    title: '',
    img: '',
    date: '',
    role: UserRole.Admin
};

export const adminRoot = '/app';

export const defaultCountry = 'Philippines';

export const servicePath = 'https://api.coloredstrategies.com';

export const themeColorStorageKey = '__theme_selected_color';

export const defaultColor = 'light.bluenavy';

export const defaultDirection = 'ltr';

export const themeRadiusStorageKey = '__theme_radius';

export const isMultiColorActive = false;

export const isDarkSwitchActive = false;

export const isAuthGuardActive = true;

export const isLocaleActive = false;

export const colors = [
    'bluenavy',
    'blueyale',
    'blueolympic',
    'greenmoss',
    'greenlime',
    'purplemonster',
    'orangecarrot',
    'redruby',
    'yellowgranola',
    'greysteel'
];

/**
 * Application environment setup in environment configuration.
 *
 * @type {string}
 */
export const appEnv = process.env.REACT_APP_ENVIRONMENT.toLowerCase();

// export const apiUrl = process.env.REACT_APP_API_URL.replace(/\/+$/, '') + '/';

/**
 * Name of the key by which authorization token will be stored in localStorage.
 *
 * @type {string}
 */
export const AUTH_TOKEN_NAME = 'Authorization';

/**
 * Name of the key by which user object will be stored in localStorage.
 *
 * @type {string}
 */
export const USER_OBJECT_NAME = 'auth_user';

/**
 * Application root folder name.
 *
 * @type {string}
 */
export const APP_ROOT_FOLDER = process.env.REACT_APP_ROOT_FOLDER;

/**
 * Disable all console errors for react-intl.
 */
const consoleError = console.error.bind(console);

console.error = (error, ...args) => {
    if (typeof error?.message === 'string' && error.message.includes('MISSING_TRANSLATION')) {
        return;
    }

    consoleError(error, ...args);
};

export const s3Url = 'https://d1vlmqfyiyslfk.cloudfront.net';

export const aesSecretIv = 'lEvk05JDwCSuKEUTCmmcMQ==';
