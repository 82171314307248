import React, {Suspense} from 'react';

import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';

import {configureStore} from './redux/store';
import * as serviceWorker from './serviceWorker';

const App = React.lazy(() => import(/* webpackChunkName: "App" */ './App'));

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Provider store={configureStore()}>
        <Suspense fallback={<div className="loading" />}>
            <App />
        </Suspense>
    </Provider>
);
/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
