import moment from 'moment';
import {all, call, fork, put, takeEvery} from 'redux-saga/effects';

import {
    countryWiseError,
    forgotPasswordError,
    forgotPasswordSuccess,
    googleAuthError,
    googleAuthSuccess,
    loginUserError,
    loginUserSuccess,
    registerUserError,
    registerUserSuccess,
    resetPasswordError,
    resetPasswordSuccess
} from './actions';
import {getAllCountryNames} from '../../constants/apiUrls';
import {adminRoot, appEnv, currentUser} from '../../constants/defaultValues'
import Auth from '../../helpers/auth.service';
import common from '../../helpers/common';
import Request from '../../helpers/request.service';
import Storage from '../../helpers/storage.service';
import {getCurrentUser, setCurrentUser} from '../../helpers/Utils';
import {
    CHANGE_COUNTRY,
    changeCountry, changeCountryComplete,
    FORGOT_PASSWORD,
    GOOGLE_AUTH,
    LOGIN_USER,
    LOGOUT_USER,
    REGISTER_USER,
    RESET_PASSWORD
} from '../actions';

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (email, password) => {
    return Request.post('admin/login', {email, password, 'app-env': appEnv, 'requestFrom': 'new-pc-admin'}, true);
}

const getCountryParameters = async (token) => {
    return Request.post('GetCountryParameters', {token});
}

const getTokenUser = async (token, isGlobal) => {
    // const body = 'token=' + token;
    return Request.post('GetAuthUser', {token}, isGlobal);
}

const getCountryLevelJwtForUser = async (countryLevelUserId) => {
    return Request.post('admin/getCountryLevelJwtForUser', {'data': {id: btoa(btoa(btoa(countryLevelUserId)))}});
}

// eslint-disable-next-line no-unused-vars
const getCountryLevelUserInfo = (countryLevelUserId, smallCountryName) => {
    // todo: show loader overlay
    getCountryLevelJwtForUser(countryLevelUserId)
        .then((responseJson) => responseJson)
        .then((response) => {
            const countryToken = atob(atob(atob(response?.data?.data)));

            if (countryToken) {
                localStorage.setItem('country_with_token', JSON.stringify({[smallCountryName]: countryToken}));
            }

            // todo: hide loader overlay
            getCountryParameters(countryToken)
                .then((responseJson) => responseJson)
                .then((response) => {
                    localStorage.setItem('country_parameters', JSON.stringify(response?.data?.data));
                });
            getTokenUser(countryToken, false).then((responseJson) => responseJson)
                .then((response) => {
                    localStorage.setItem('country_admin_auth_data', JSON.stringify(response.data));
                });
        })
}

function* watchChangeCountry() {
    yield takeEvery(CHANGE_COUNTRY, getCountryLevelUser);
}

function* getCountryLevelUser({payload}) {
    if (payload !== undefined) {
        const {countryUserId, countryShortName, history} = payload;
        const countryJson = {
            countryUserId: countryUserId,
            countryShortName: countryShortName,
            'history': history
        };

        common.setDataInLocalStorage('current_selected_country', countryJson);
        Storage.setObject('pimSelectedCountry', {country: getAllCountryNames()[countryShortName]})

        try {
            const countryLevelJwtForUser = yield call(getCountryLevelJwtForUser, countryUserId, countryShortName);
            const countryWiseErrors = {};
            const adminUserEmail = Auth?.loggedInAdminUserInfo ? JSON.parse(Auth.loggedInAdminUserInfo)?.UserData?.email : '';

            if (countryLevelJwtForUser?.data?.status !== 'success') {

                if (countryLevelJwtForUser?.data?.message === 'password expired') {
                    Request.post('logout', {token: Auth.token});
                    Auth.logout();
                    history(adminRoot);
                    yield call(forgotPasswordAsync, adminUserEmail, true);
                    const msgString = 'Your password has expired, please use email sent to you to reset your password';
                    yield put(loginUserError(msgString));
                    return false;
                }

                // eslint-disable-next-line no-unused-expressions
                Object.keys(countryWiseErrors).length ? '' : countryWiseErrors['country_level_jwt_for_user'] = countryLevelJwtForUser?.data?.message;
            }

            const countryToken = atob(atob(atob(countryLevelJwtForUser?.data?.data)));
            localStorage.setItem('country_with_token', JSON.stringify({[countryShortName]: countryToken}));

            const countryParameters = yield call(getCountryParameters, countryToken);

            if (Object.keys(countryParameters?.data?.data).length === 0) {
                // eslint-disable-next-line no-unused-expressions
                Object.keys(countryWiseErrors).length ? '' : countryWiseErrors['country_parameters'] = 'No country parameter found';
            } else {
                localStorage.setItem('country_parameters', JSON.stringify(countryParameters?.data?.data));
                const tokenUser = yield call(getTokenUser, countryToken);

                if (tokenUser.data.message !== 'Valid Login') {
                    // eslint-disable-next-line no-unused-expressions
                    Object.keys(countryWiseErrors).length ? '' : countryWiseErrors['get_token_user'] = (tokenUser?.data?.message || tokenUser?.data?.status);
                } else {
                    localStorage.setItem('country_admin_auth_data', JSON.stringify(tokenUser.data));
                    localStorage.setItem('past_selected_country', JSON.stringify(payload));
                    localStorage.setItem('trigger', 'false');
                }
            }

            if (Object.keys(countryWiseErrors).length > 0) {
                yield put(countryWiseError(countryWiseErrors));
            }

            yield put(changeCountryComplete(Object.keys(countryWiseErrors).length === 0));

        } catch (error) {
            console.error(error);

            if (error.isAxiosError) {
                const msgString = 'Instance not found. Redirecting to previous selected instance';
                yield put(countryWiseError({country_level_jwt_for_user: (msgString)}));

                const pastSelectedCountry = JSON.parse(localStorage.getItem('past_selected_country'));

                if (!pastSelectedCountry?.countryShortName) {
                    const prevCountryJson = common.getAuthCountries()[0];
                    pastSelectedCountry.countryShortName = prevCountryJson.country;
                    pastSelectedCountry.countryUserId = prevCountryJson.userid;
                }

                Auth.country = getAllCountryNames()[pastSelectedCountry?.countryShortName];
                yield put(changeCountry(pastSelectedCountry.countryUserId, pastSelectedCountry?.countryShortName, pastSelectedCountry.history));
                return false;
            }

            yield put(countryWiseError({country_level_jwt_for_user: (error.response?.data?.status === 'error' ? error.response?.data?.message : 'Network Error')}));
        }
    }
}

function* loginWithEmailPassword({payload}) {
    const {email, password} = payload.user;
    const {history} = payload;

    try {
        const loginUser = yield call(loginWithEmailPasswordAsync, email, password);

        if (loginUser.data.status === 'ok') {
            Auth.token = loginUser.data.token;
            const item = loginUser.data.user_info;

            let country = '';
            const countryAccessJson = (JSON.parse(item?.more_details)?.country_access || []).filter((row) => row?.is_accessible ?? true)?.[0];
            country = countryAccessJson?.country;

            if (countryAccessJson === undefined) {
                yield put(loginUserError('Country access not found'));
                return false;
            }

            if (!country) {
                country = common.selectedCountry()?.short_name;
            }

            const countryJson = {
                countryUserId: countryAccessJson.userid,
                countryShortName: country,
                'history': history
            };
            common.setDataInLocalStorage('current_selected_country', countryJson);
            Storage.setObject('pimSelectedCountry', {country: getAllCountryNames()[country]})

            Auth.user = item;
            setCurrentUser(item);

            let userData = yield call(getTokenUser, loginUser.data.token, true);
            userData = userData.data;
            userData.pcadmin_idle_time = loginUser.data.pcadmin_idle_time;

            // eslint-disable-next-line no-undef
            if (_.isArray(userData?.UserData)) {
                if (userData?.UserData.length === 0) {
                    yield put(loginUserError(userData?.message));
                    return false;
                }
            } else if (userData?.message !== 'Valid Login') {
                yield put(loginUserError(userData?.message));
                return false;
            }

            localStorage.setItem('AdminAuthData', JSON.stringify(userData));

            // set login time as per browser's time zone
            localStorage.setItem('loggedInAt', moment().format('YYYY-MM-DD HH:mm:ss'));
            localStorage.setItem('logOutAt', moment().add(179, 'minutes').format('YYYY-MM-DD HH:mm:ss'));

            const countryLevelJwtForUser = yield call(getCountryLevelJwtForUser, countryAccessJson?.userid, countryAccessJson?.country);
            const countryWiseErrors = {};

            if (countryLevelJwtForUser?.data?.status !== 'success') {

                /* yield put(loginUserError(countryLevelJwtForUser?.data?.message === 'password expired' ? 'Password expired for ' + countryAccessJson?.country + ' instance. Please update your password' : 'Token not found for respective country'));*/
                if (countryLevelJwtForUser?.data?.message === 'password expired') {
                    yield call(forgotPasswordAsync, userData?.UserData?.email, true);
                    const msgString = 'Your password has expired, please use email sent to you to reset your password';
                    yield put(loginUserError(msgString));
                    return false;
                }

                countryWiseErrors['country_level_jwt_for_user'] = countryLevelJwtForUser?.data?.message;
            }

            if (Object.keys(countryWiseErrors).length > 0) {
                yield put(countryWiseError(countryWiseErrors));
                Auth.logout();
                return false;
            }

            const countryToken = atob(atob(atob(countryLevelJwtForUser?.data?.data)));
            localStorage.setItem('country_with_token', JSON.stringify({[countryAccessJson?.country]: countryToken}));

            const countryParameters = yield call(getCountryParameters, countryToken);

            if (Object.keys(countryParameters?.data?.data).length === 0) {
                countryWiseErrors['country_parameters'] = 'No country parameter found';
            } else {
                localStorage.setItem('country_parameters', JSON.stringify(countryParameters?.data?.data));
                const tokenUser = yield call(getTokenUser, countryToken);

                if (tokenUser.data.message !== 'Valid Login') {
                    countryWiseErrors['get_token_user'] = countryAccessJson?.country.toUpperCase() + ' - ' + tokenUser?.data?.message;
                } else {
                    /*
                    {"countryUserId":760,"countryShortName":"id","history}}
                     */
                    const countryJson = {
                        countryUserId: countryAccessJson?.userid,
                        countryShortName: countryAccessJson?.country,
                        'history': payload.history
                    };
                    common.setDataInLocalStorage('past_selected_country', countryJson);
                    yield put(changeCountry(countryJson.countryUserId, countryJson?.countryShortName, countryJson.history));
                    // localStorage.setItem('past_selected_country', countryJson);
                }
            }

            if (Object.keys(countryWiseErrors).length > 0) {
                yield put(countryWiseError(countryWiseErrors));
            }

            yield put(loginUserSuccess(item));
            history(adminRoot);
        }

        yield put(loginUserError(loginUser.message));

    } catch (error) {
        console.error(error);
        const message = error?.response?.data?.status === 'error' ? error?.response?.data?.message : (error?.data?.status === 'error' ? error?.data?.message : 'Network Error');
        yield put(loginUserError(message));
    }
}

export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (email, password) =>
    // eslint-disable-next-line no-undef
    await auth
        .createUserWithEmailAndPassword(email, password)
        .then((user) => user)
        .catch((error) => error);

function* registerWithEmailPassword({payload}) {
    const {email, password} = payload.user;
    const {history} = payload;

    try {
        const registerUser = yield call(
            registerWithEmailPasswordAsync,
            email,
            password
        );

        if (!registerUser.message) {
            const item = {uid: registerUser.user.uid, ...currentUser};
            setCurrentUser(item);
            yield put(registerUserSuccess(item));
            history(adminRoot);
        } else {
            yield put(registerUserError(registerUser.message));
        }
    } catch (error) {
        console.error(error);
        yield put(registerUserError(error));
    }
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (navigate) => {
    Request.post('logout', {token: Auth.token});
    Auth.logout();
    navigate('/user/login');
};

function* logout({payload}) {
    const {history} = payload;
    setCurrentUser();
    yield call(logoutAsync, history);
}

export function* watchForgotPassword() {
    yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email, isPasswordExpired = false) => {
    return Request.post('admin/forgotPassword', {
        email,
        'requestFrom': 'new-pc-admin',
        isPasswordExpired: isPasswordExpired
    }, true);
    // return await auth.sendPasswordResetEmail(email).then((user) => user).catch((error) => error);
};

function* forgotPassword({payload}) {
    const {email} = payload.forgotUserMail;

    try {
        const forgotPasswordStatus = yield call(forgotPasswordAsync, email);

        if (forgotPasswordStatus.data.status === 'ok') {
            yield put(forgotPasswordSuccess(forgotPasswordStatus.data.message));
        } else {
            yield put(forgotPasswordError(forgotPasswordStatus.data.message));
        }
    } catch (error) {
        yield put(forgotPasswordError(error));
    }
}

export function* watchResetPassword() {
    yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword, countryUrls, resetToken) => {
    const postBody = {
        token: Auth.token,
        'ResetToken': resetToken,
        'password': newPassword,
        'repassword': newPassword,
        'countryUrls': countryUrls,
        'requestFrom': 'new-pc-admin'
    };
    return Request.post('admin/resetPassword', postBody, true);
};

function* resetPassword({payload}) {
    const {newPassword, resetPasswordCode, countryUrls, resetToken} = payload;

    try {
        const resetPasswordStatus = yield call(
            resetPasswordAsync,
            resetPasswordCode,
            newPassword,
            countryUrls,
            resetToken
        );

        if (resetPasswordStatus.data.status === 'ok') {
            yield put(resetPasswordSuccess(resetPasswordStatus.data.message));
        } else {
            yield put(resetPasswordError(resetPasswordStatus.data.message));
        }
    } catch (error) {
        yield put(resetPasswordError(error));
    }
}

export function* watchGoogleAuth() {
    yield takeEvery(GOOGLE_AUTH, googleAuth);
}

const googleAuthAsync = async ({...formData}) => {
    const {email, otp, qrImg} = formData;
    const googleSecretCode = formData.auth_secret_code;

    return Request.post('admin/getGoogle2FA', {token: Auth.token, email, otp, googleSecretCode, qrImg}, true)
        .then((user) => user)
        .catch((error) => error);
};

function* googleAuth({payload}) {
    // eslint-disable-next-line no-unused-vars
    const {history} = payload;

    try {
        const googleAuthResponse = yield call(googleAuthAsync, payload.formData);

        if (googleAuthResponse?.data?.status === 'ok') {
            const authUserInfo = Auth.user;
            authUserInfo.is_auth_secret_code_valid = googleAuthResponse.data.isGoogleSecretCodeValid;
            Auth.user = authUserInfo;
            const item = getCurrentUser();
            item.is_auth_secret_code_valid = googleAuthResponse.data.isGoogleSecretCodeValid;
            setCurrentUser(item);
            yield put(googleAuthSuccess(googleAuthResponse.data.message));

            history(adminRoot);
        } else {
            yield put(googleAuthError(googleAuthResponse.data.message));
        }
    } catch (error) {
        yield put(googleAuthError(error));
    }
}

export default function* rootSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchLogoutUser),
        fork(watchRegisterUser),
        fork(watchForgotPassword),
        fork(watchResetPassword),
        fork(watchGoogleAuth),
        fork(watchChangeCountry)
    ]);
}
