import common from './common';
import {history} from './history';
import Request from './request.service';
import Storage from './storage.service';
import {AUTH_TOKEN_NAME, USER_OBJECT_NAME} from '../constants/defaultValues';

export default class Auth {

    static set token(token) {
        Storage.token = token;
    }

    static get token() {
        return Storage.token;
    }

    static get countryToken() {
        try {
            return this.userInfo?.country_with_token ? JSON.parse(this.userInfo?.country_with_token)[common.selectedCountry()?.short_name] : '';
        } catch (e) {
            console.error(e)
            return '';
        }
    }

    static set country(country) {
        Storage.setObject('pimSelectedCountry', {country: country});
    }

    static get country() {
        return Storage.getObject('pimSelectedCountry')?.country;
    }

    static logout() {
        Storage.remove(USER_OBJECT_NAME);
        Storage.remove(AUTH_TOKEN_NAME);
        localStorage.removeItem('loggedInAt');
        localStorage.removeItem('logOutAt');
        history.push('/user/login');
    }

    static set user(user) {
        Storage.setObject(USER_OBJECT_NAME, user)
    }

    static get user() {
        return Storage.getObject(USER_OBJECT_NAME);
    }

    static get check() {
        const token = this.token;
        const user = this.user;
        return !(!token || !user);
    }

    static checkApi() {
        return Request.get(Request.apiUrl + 'vendor/user');
    }

    static get userInfo() {
        return localStorage;
    }

    static get loggedInAdminUserInfo() {
        return localStorage.AdminAuthData;
    }

}
