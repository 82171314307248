import {appEnv} from './defaultValues';
import Storage from '../helpers/storage.service';

const allCountryNames = {
    au: 'Australia',
    id: 'Indonesia',
    in: 'India',
    lk: 'Sri Lanka',
    ph: 'Philippines',
    sg: 'Singapore'
    // my: 'Malaysia'
    // th: 'Thailand',
};

/**
 * This variable stores the Base API URL of each environment for each country
 * @type {{}}
 */
const loginLogoutUrls = {
    // local: 'http://pimlocal.api/api/',
    // local: 'http://pimpeerconnexions.api/api/',
    // local: 'http://127.0.0.3/api/',
    // local: 'http://peerconnexions.api/api/',
    // local: 'http://peerconnexions.api/api/',
    // local: 'http://127.0.0.1:8000/api/',
    // local: 'http://127.0.0.3/api/',
    // local: 'http://127.0.0.1:8000/api/',
    local: 'http://peerconnexions.api/api/',
    dev: 'https://dev-pim-api.peerconnexions.com/api/',
    uat: 'https://uat-pim-api.peerconnexions.com/api/',
    staging: 'https://staging-pim-api.peerconnexions.com/api/',
    demo: 'https://demo-pim-api.peerconnexions.com/api/',
    prod: 'https://pim-api.peerconnexions.com/api/',
    sandbox: 'https://sandbox-pim-api.peerconnexions.com/api/'
};

/**
 * Login/logout URLs for global instance environments.
 */
const globalLoginLogoutUrls = {
    // local: 'http://pimlocal.api/api/',
    // local: 'http://pimpeerconnexions.api/api/',
    // local: 'http://127.0.0.2/api/',
    // local: 'http://peerconnexions.api/api/',
    // local: 'http://peerconnexions.api/api/',
    // local: 'http://127.0.0.1:8000/api/',
    // local: 'http://127.0.0.2/api/',
    // local: 'http://127.0.0.1:8000/api/',
    local: 'http://peerconnexions.api/api/',
    dev: 'https://dev-pim-api.peerconnexions.com/api/',
    uat: 'https://uat-global-api.peerconnexions.com/api/',
    staging: 'https://staging-global-api.peerconnexions.com/api/',
    demo: 'https://demo-pim-api.peerconnexions.com/api/',
    prod: 'https://pim-api.peerconnexions.com/api/',
    sandbox: 'https://sandbox-pim-api.peerconnexions.com/api/'
};

const apiUrls = {
    local: {
        // ph: 'http://127.0.0.2/api/',
        // ph: 'http://peerconnexions.api/api/',
        // ph: 'http://127.0.0.1:8000/api/',
        // ph: 'http://127.0.0.2/api/',
        ph: 'http://peerconnexions.api/api/',
        // ph: 'http://127.0.0.1:8000/api/',
        id: 'http://peerconnexions.api/api/',
        // th: 'http://thlocal.api/api/',
        in: 'http://inlocal.api/api/',
        lk: 'http://lklocal.api/api/',
        sg: 'http://sglocal.api/api/',
        my: 'http://mylocal.api/api/',
        au: 'http://aulocal.api/api/'
    },
    dev: {
        ph: 'https://ph-dev-api.peerconnexions.com/api/',
        id: 'https://id-dev-api.peerconnexions.com/api/',
        // th: 'https://th-dev-api.peerconnexions.com/api/',
        in: 'https://in-dev-api.peerconnexions.com/api/',
        lk: 'https://lk-dev-api.peerconnexions.com/api/',
        sg: 'https://sg-dev-api.peerconnexions.com/api/',
        // my: 'https://my-dev-api.peerconnexions.com/api/',
        au: 'https://au-dev-api.peerconnexions.com/api/'
    },
    uat: {
        ph: 'https://ph-uat-api.peerconnexions.com/api/',
        id: 'https://id-uat-api.peerconnexions.com/api/',
        // th: 'https://th-uat-api.peerconnexions.com/api/',
        in: 'https://in-uat-api.peerconnexions.com/api/',
        lk: 'https://lk-uat-api.peerconnexions.com/api/',
        sg: 'https://sg-uat-api.peerconnexions.com/api/',
        // my: 'https://my-uat-api.peerconnexions.com/api/',
        au: 'https://au-uat-api.peerconnexions.com/api/'
    },
    staging: {
        ph: 'https://ph-staging-api.peerconnexions.com/api/',
        id: 'https://id-staging-api.peerconnexions.com/api/',
        // th: 'https://th-staging-api.peerconnexions.com/api/',
        in: 'https://in-staging-api.peerconnexions.com/api/',
        lk: 'https://lk-staging-api.peerconnexions.com/api/',
        sg: 'https://sg-staging-api.peerconnexions.com/api/',
        // my: 'https://my-staging-api.peerconnexions.com/api/',
        au: 'https://au-staging-api.peerconnexions.com/api/'
    },
    prod: {
        ph: 'https://ph-api.peerconnexions.com/api/',
        id: 'https://id-api.peerconnexions.com/api/',
        // th: 'https://th-api.peerconnexions.com/api/',
        in: 'https://in-api.peerconnexions.com/api/',
        lk: 'https://lk-api.peerconnexions.com/api/',
        sg: 'https://sg-api.peerconnexions.com/api/',
        // my: 'https://my-api.peerconnexions.com/api/',
        au: 'https://au-api.peerconnexions.com/api/'
    },
    sandbox: {
        ph: 'https://ph-sandbox-api.peerconnexions.com/api/',
        id: 'https://id-sandbox-api.peerconnexions.com/api/',
        // th: 'https://th-sandbox-api.peerconnexions.com/api/',
        in: 'https://in-sandbox-api.peerconnexions.com/api/',
        lk: 'https://lk-sandbox-api.peerconnexions.com/api/',
        sg: 'https://sg-sandbox-api.peerconnexions.com/api/',
        // my: 'https://my-sandbox-api.peerconnexions.com/api/',
        au: 'https://au-sandbox-api.peerconnexions.com/api/'
    },
    demo: {
        ph: 'https://ph-demo-api.peerconnexions.com/api/',
        id: 'https://id-demo-api.peerconnexions.com/api/',
        // th: 'https://th-demo-api.peerconnexions.com/api/',
        in: 'https://in-demo-api.peerconnexions.com/api/',
        lk: 'https://lk-demo-api.peerconnexions.com/api/',
        sg: 'https://sg-demo-api.peerconnexions.com/api/',
        // my: 'https://my-demo-api.peerconnexions.com/api/',
        au: 'https://au-demo-api.peerconnexions.com/api/'
    }
};

/**
 * API URLs for global instance environments.
 */
const globalApiUrls = {
    uat: {
        ph: 'https://uat-api-1.peerconnexions.com/api/',
        id: 'https://uat-api-1.peerconnexions.com/api/',
        // th: 'https://th-uat-api.peerconnexions.com/api/',
        in: 'https://uat-api-1.peerconnexions.com/api/',
        lk: 'https://uat-api-1.peerconnexions.com/api/',
        sg: 'https://uat-api-1.peerconnexions.com/api/',
        // my: 'https://my-uat-api.peerconnexions.com/api/',
        au: 'https://uat-api-2.peerconnexions.com/api/'
    },
    staging: {
        ph: 'https://staging-api-1.peerconnexions.com/api/',
        id: 'https://staging-api-1.peerconnexions.com/api/',
        // th: 'https://th-staging-api.peerconnexions.com/api/',
        in: 'https://staging-api-2.peerconnexions.com/api/',
        lk: 'https://staging-api-2.peerconnexions.com/api/',
        sg: 'https://staging-api-4.peerconnexions.com/api/',
        // my: 'https://my-staging-api.peerconnexions.com/api/',
        au: 'https://staging-api-3.peerconnexions.com/api/'
    }
};

const websiteBaseUrls = {
    local: {
        ph: 'http://localhost:3000/',
        id: 'http://localhost:3000/',
        // th: 'http://localhost:3000/',
        in: 'http://localhost:3000/',
        lk: 'http://localhost:3000/',
        sg: 'http://localhost:3000/',
        // my: 'http://localhost:3000/',
        au: 'http://localhost:3000/'
    },
    dev: {
        ph: 'https://ph-dev.peerconnexions.com/',
        id: 'https://id-dev.peerconnexions.com/',
        // th: 'https://th-dev.peerconnexions.com/',
        in: 'https://in-dev.peerconnexions.com/',
        lk: 'https://lk-dev.peerconnexions.com/',
        sg: 'https://sg-dev.peerconnexions.com/',
        // my: 'https://my-dev.peerconnexions.com/',
        au: 'https://au-dev.peerconnexions.com/'
    },
    uat: {
        ph: 'https://ph-uat.peerconnexions.com/',
        id: 'https://id-uat.peerconnexions.com/',
        // th: 'https://th-uat.peerconnexions.com/',
        in: 'https://in-uat.peerconnexions.com/',
        lk: 'https://lk-uat.peerconnexions.com/',
        sg: 'https://sg-uat.peerconnexions.com/',
        // my: 'https://my-uat.peerconnexions.com/',
        au: 'https://au-uat.peerconnexions.com/'
    },
    staging: {
        ph: 'https://ph-staging.peerconnexions.com/',
        id: 'https://id-staging.peerconnexions.com/',
        // th: 'https://th-staging.peerconnexions.com/',
        in: 'https://in-staging.peerconnexions.com/',
        lk: 'https://lk-staging.peerconnexions.com/',
        sg: 'https://sg-staging.peerconnexions.com/',
        // my: 'https://my-staging.peerconnexions.com/',
        au: 'https://au-staging.peerconnexions.com/'
    },
    prod: {
        ph: 'https://ph.peerconnexions.com/',
        id: 'https://id.peerconnexions.com/',
        // th: 'https://th.peerconnexions.com/',
        in: 'https://in.peerconnexions.com/',
        lk: 'https://lk.peerconnexions.com/',
        sg: 'https://sg.peerconnexions.com/',
        // my: 'https://my.peerconnexions.com/',
        au: 'https://au.peerconnexions.com/'
    },
    sandbox: {
        ph: 'https://ph-sandbox.peerconnexions.com/',
        id: 'https://id-sandbox.peerconnexions.com/',
        // th: 'https://th-sandbox.peerconnexions.com/',
        in: 'https://in-sandbox.peerconnexions.com/',
        lk: 'https://lk-sandbox.peerconnexions.com/',
        sg: 'https://sg-sandbox.peerconnexions.com/',
        // my: 'https://my-sandbox.peerconnexions.com/',
        au: 'https://au-sandbox.peerconnexions.com/'
    },
    demo: {
        ph: 'https://ph-demo.peerconnexions.com/',
        id: 'https://id-demo.peerconnexions.com/',
        // th: 'https://th-demo.peerconnexions.com/',
        in: 'https://in-demo.peerconnexions.com/',
        lk: 'https://lk-demo.peerconnexions.com/',
        sg: 'https://sg-demo.peerconnexions.com/',
        // my: 'https://my-demo.peerconnexions.com/',
        au: 'https://au-demo.peerconnexions.com/'
    }
};

export const getApiUrl = (country) => {
    return process?.env?.REACT_APP_IS_GLOBAL_INSTANCE && globalApiUrls[appEnv] ?
        globalApiUrls[appEnv][country] :
        apiUrls[appEnv][country];
};

export const getApiUrlsByAppEnv = () => {
    return process?.env?.REACT_APP_IS_GLOBAL_INSTANCE && globalApiUrls[appEnv] ?
        globalApiUrls[appEnv] :
        apiUrls[appEnv];
};

export const getApiUrlForLoginLogout = (appEnv) => {
    return process?.env?.REACT_APP_IS_GLOBAL_INSTANCE ?
        globalLoginLogoutUrls[appEnv] :
        loginLogoutUrls[appEnv];
};

export const getCountryNames = () => {
    const countryNames = {};

    for (const country in apiUrls[appEnv]) {
        if (allCountryNames[country]) {
            countryNames[country] = allCountryNames[country];
        }
    }

    return countryNames;

};

export const getAllCountryNames = () => {
    return allCountryNames;
};

/* for ph prod url is differ so it will generate dynamically */
export const getS3RootFolderName = () => {
    const countryName = localStorage?.pimSelectedCountry ? JSON.parse(localStorage?.pimSelectedCountry)['country'] : '';
    const country = Object.entries(allCountryNames).filter(row => row[1] === countryName);
    const shortCountryName = country.length > 0 ? country[0][0] : '';

    const countryParams = Storage.getObject('country_parameters');

    let s3RootFolderName = countryParams?.country_iso_code + '.peerconnexions.com';

    if (appEnv === 'local') {
        s3RootFolderName = 'peerconnexions.api';
    } else if (shortCountryName === 'ph') {
        s3RootFolderName = 'prod.peerconnexions.com.ph';
    }

    return s3RootFolderName;
};

/* for ph prod url is differ so it will generate dynamically */
const generateS3Url = (baseUrl) => {
    const countryName = localStorage?.pimSelectedCountry ? JSON.parse(localStorage?.pimSelectedCountry)['country'] : '';
    const country = Object.entries(allCountryNames).filter(row => row[1] === countryName);
    const shortCountryName = country.length > 0 ? country[0][0] : '';

    const countryParams = Storage.getObject('country_parameters');

    let domainName = countryParams?.country_iso_code + '.peerconnexions.com/';

    if (shortCountryName === 'ph') {
        domainName = 'prod.peerconnexions.com.ph/';
    }

    // return 'https://d13bdikcc3v8q0.cloudfront.net/' + domainName;
    return baseUrl + domainName;
};

export const getS3Domain = () => {
    const s3Domains = {
        local: generateS3Url('https://d3mhiuu2ipghcl.cloudfront.net/'),
        dev: generateS3Url('https://d3mhiuu2ipghcl.cloudfront.net/'),
        uat: generateS3Url('https://d3mhiuu2ipghcl.cloudfront.net/'),
        staging: generateS3Url('https://d3mhiuu2ipghcl.cloudfront.net/'),
        prod: generateS3Url('https://d13bdikcc3v8q0.cloudfront.net/'),
        demo: generateS3Url('https://d3mhiuu2ipghcl.cloudfront.net/'),
        sandbox: generateS3Url('https://d3mhiuu2ipghcl.cloudfront.net/')
    };

    return s3Domains[appEnv];
};

export const getWebsiteApi = (country) => {
    return websiteBaseUrls[appEnv][country];
};
