import axios from 'axios';
import {getCountryCodeFromStorage} from 'pc-utils/admin';
import {downloadBlobData, encodeQuery, serialize} from 'pc-utils/form';

import Auth from './auth.service';
import {errorHandler, requestInterceptor, successHandler} from './interceptors';
import {getApiUrl, getApiUrlForLoginLogout} from '../constants/apiUrls';
import {appEnv} from '../constants/defaultValues';

// request interceptor
axios.interceptors.request.use(
    request => requestInterceptor(request)
);
// response interceptor
axios.interceptors.response.use(
    response => successHandler(response),
    error => errorHandler(error)
);

export default class Request {

    /**
     * Getter function to get API URL.
     *
     * @returns {string}
     */
    static get apiUrl() {
        const countryCode = getCountryCodeFromStorage();
        return getApiUrl(countryCode);
    }

    /**
     * Function to send GET request.
     *
     * @param url
     * @param queryParams
     * @returns {Promise<AxiosResponse<any>>}
     */
    static get = (url, queryParams = '', isGlobal = false) => {
        queryParams = !!queryParams ? '?' + encodeQuery(queryParams) : '';
        const token = isGlobal ? Auth.token : Auth.countryToken;

        const options = {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Accept': 'application/json',
                'x-country-code': getCountryCodeFromStorage(isGlobal)
            }
        }
        return axios.get(this.apiUrl + url + queryParams, options);
    };

    /**
     * Function to send POST request. Just pass a JavaScript object to this function,
     * it will automatically convert it into FormData object.
     *
     * @param url
     * @param payload
     * @param isGlobal
     * @returns {Promise<AxiosResponse<any>>}
     */
    static post = (url, payload, isGlobal = false) => {
        const token = isGlobal ? Auth.token : Auth.countryToken;

        if (token) {
            payload['token'] = token;
        }

        const options = {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Accept': 'application/json',
                'x-country-code': getCountryCodeFromStorage(isGlobal)
            }
        }

        const body = serialize(payload);
        const baseUrl = isGlobal ? this.loginLogoutApiUrl : this.apiUrl;

        return axios.post(baseUrl + url, body, options);
    };

    /**
     * Function to send JSON data via POST request.
     *
     * @param url
     * @param payload
     * @param isGlobal
     * @returns {Promise<AxiosResponse<any>>}
     */
    static postJson = (url, payload, isGlobal = false) => {
        const token = isGlobal ? Auth.token : Auth.countryToken;
        const baseUrl = isGlobal ? this.loginLogoutApiUrl : this.apiUrl;

        payload['token'] = token;

        const options = {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-country-code': getCountryCodeFromStorage(isGlobal)
            }
        }

        return axios.post(baseUrl + url, payload, options);
    };

    /**
     * Function to send PUT request. Just pass a JavaScript object to this function,
     * it will automatically convert it into FormData object.
     *
     * @param url
     * @param payload
     * @returns {Promise<AxiosResponse<any>>}
     */
    static put = (url, payload) => {
        const body = serialize(payload);
        return axios.put(this.apiUrl + url, body);
    };

    /**
     * Function to send JSON data via PUT request.
     *
     * @param url
     * @param payload
     * @returns {Promise<AxiosResponse<any>>}
     */
    static putJson = (url, payload) => {
        return axios.put(this.apiUrl + url, payload, {headers: {'Content-Type': 'application/json'}});
    };

    /**
     * Function to send DELETE request.
     *
     * @param url
     * @returns {Promise<AxiosResponse<any>>}
     */
    static delete = (url) => {
        return axios.delete(this.apiUrl + url);
    };

    /**
     * Helper function to convert array/objects into FormData object.
     *
     * @param {object}payload
     * @returns {FormData}
     */
    static getFormData = function (payload) {
        return serialize(payload);
    };

    /**
     * Function to download a file via GET request.
     *
     * @param url
     * @param fileName
     * @param queryParams
     * @returns {Promise<AxiosResponse<any>>}
     */
    static downloadFileViaGet = (url, fileName, queryParams = '') => {
        queryParams = !!queryParams ? '?' + encodeQuery(queryParams) : '';

        const options = {
            responseType: 'blob'
        }

        return axios.get(this.apiUrl + url + queryParams, options)
            .then((response) => {
                downloadBlobData(fileName, response.data);
            })
            .catch((error) => error);
    };

    /**
     * Function to download a file via POST request.
     *
     * @param url
     * @param fileName
     * @param data
     * @param isGlobal
     * @returns {Promise<AxiosResponse<any>>}
     */
    static downloadFileViaPost = (url, fileName, data, isGlobal = false) => {
        const token = isGlobal ? Auth.token : Auth.countryToken;
        data = `${data}&token=${token}&requestFrom=new-pc-admin`

        const options = {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Accept': 'application/json',
                'x-country-code': getCountryCodeFromStorage(isGlobal)
            },
            responseType: 'blob'
        }

        return axios.post(this.apiUrl + url, data, options)
            .then((response) => {
                downloadBlobData(fileName, response.data);
            })
            .catch((error) => error);
    };

    /**
     * Function to post data with token.
     * @author Vikrant Vishwakarma
     * @param url
     * @param payload
     * @param isGlobal
     * @returns {Promise<AxiosResponse<any>>}
     */
    static postWithToken = (url, payload, isGlobal = false) => {
        const token = isGlobal ? Auth.token : Auth.countryToken;
        const body = 'token=' + token + '&' + payload + '&requestFrom=new-pc-admin';
        const options = {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Accept': 'application/json',
                'x-country-code': getCountryCodeFromStorage(isGlobal)
            }
        }

        const baseUrl = isGlobal ? this.loginLogoutApiUrl : this.apiUrl;

        return axios.post(baseUrl + url, body, options);

    };

    static postFormData = (url, JsonData, isGlobal = true) => {
        const formData = serialize(JsonData);
        const token = isGlobal ? Auth.token : Auth.countryToken;
        formData.append('token', token);

        const options = {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Accept': 'application/json',
                'x-country-code': getCountryCodeFromStorage(isGlobal)
            }
        }

        return axios.post(this.apiUrl + url, formData, options);
        // const body = 'token=' + Auth.token + '&' + payload;
        // return axios.post(this.apiUrl + url, body);
    };

    static postPdfDownload = (url, PostBody, FileName) => {

        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            // eslint-disable-next-line no-undef
            'x-country-code': getCountryCodeFromStorage(isGlobal)
        }

        return fetch(this.apiUrl + url, {
            method: 'post',
            headers: headers,
            // body: 'token=' + localStorage.getItem('token') + '&' + PostBody
            body: PostBody
        })
            .then(Result => Result.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = FileName + '.PDF';
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                return 'ok';
            })
            .catch(() => alert('Fail!'));
    }

    static get loginLogoutApiUrl() {
        return getApiUrlForLoginLogout(appEnv);
    }

    static fileUpload = (url, formData, isGlobal = true) => {
        const token = isGlobal ? Auth.token : Auth.countryToken;
        formData.append('token', token);

        const options = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + token,
                // 'Accept': 'application/json',
                'x-country-code': getCountryCodeFromStorage(isGlobal)
            }
        }

        return axios.post(this.apiUrl + url, formData, options);
        // const body = 'token=' + Auth.token + '&' + payload;
        // return axios.post(this.apiUrl + url, body);
    };

}
