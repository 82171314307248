import Auth from './auth.service';
import {history} from './history';
import Storage from './storage.service';
import NotificationManager from '../components/common/react-notifications/NotificationManager';

// request interceptor function

export const requestInterceptor = (request) => {

    if (Auth.token) {
        /* request.headers[AUTH_TOKEN_NAME] = 'Bearer ' + Auth.token;
        request.headers['Content-Type'] = 'multipart/form-data';*/
    }

    return request
};

// response interceptor function for error
export const errorHandler = (error) => {
    if (
        (error?.response?.status === 401 && error?.response?.data?.status === 'token') ||
        error?.response?.data?.status === 'Token is Expired'
    ) {
        NotificationManager.error('', 'Token expired or invalid', 3000, null, null, 'filled');
        Storage.setFlash('error_message', 'Session expired, please login again.');
        Auth.logout();
        history.go(0);
    }

    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({...error});
};

// response interceptor function for success
export const successHandler = (response) => {

    if (['Token is Expired', 'Token is Invalid'].includes(response?.data?.status)) {
        NotificationManager.error('', 'Token expired or invalid', 3000, null, null, 'filled');
        Storage.setFlash('error_message', 'Session expired, please login again.');
        Auth.logout();
        history.go(0);
        return {data: {status: 'error', data: [], Records: []}};
    }

    return response;
};
