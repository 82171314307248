import {getCurrentLanguage} from '../../helpers/Utils';
import {CHANGE_COUNTRY, CHANGE_COUNTRY_COMPLETE, CHANGE_LOCALE} from '../actions';

const INIT_STATE = {
    locale: getCurrentLanguage(),
    changingCountry: false
};

export default (state = INIT_STATE, action) => {

    switch (action.type) {
        case CHANGE_LOCALE:
            return {...state, locale: action.payload};
        case CHANGE_COUNTRY:
            return {...state, change_country: action.payload, changingCountry: true};
        case CHANGE_COUNTRY_COMPLETE:
            return {...state, changingCountry: false};
        default:
            return {...state};
    }
};
