/* eslint-disable no-unused-vars */
import React from 'react';

import _ from 'lodash';
import {Navigate, useLocation} from 'react-router-dom';

import Auth from './auth.service';
import common from './common';
import {getCurrentUser, isAuthTokenExpired} from './Utils'
import {isAuthGuardActive} from '../constants/defaultValues';

const ProtectedRoute = ({
    path,
    component: Component,
    roles = undefined,
    moduleId = null,
    subModuleId = null,
    ...rest
}) => {
    const location = useLocation();

    const setComponent = (props) => {
        if (isAuthGuardActive) {

            const currentUser = getCurrentUser();

            if (isAuthTokenExpired()) {
                Auth.logout();
            }

            if (currentUser && Auth.token) {
                if (!!currentUser?.is_auth_secret_code_valid === false) {
                    return <Navigate
                        to={{
                            pathname: '/user/google-authentication',
                            state: {from: props.location}
                        }}/>

                }

                const permissionObject = common.getAuthUserRolePermissionObject();

                if (currentUser?.role !== 7 && moduleId !== 'default') {
                    if ((!moduleId && !subModuleId) && location.pathname !== '/app') {
                        return <Navigate
                            to={{
                                pathname: '/app/access-denied',
                                state: {from: props.location}
                            }}/>
                    }

                }

                const subModuleJson = common.getSubModulePermissionJson(subModuleId, permissionObject);
                common.setCurrentPageAccessObject(subModuleJson);
                
                if (currentUser?.role !== 7 && moduleId !== 'default') {                    

                    if (moduleId || subModuleId) {
                        
                        const isNotAccessible = subModuleId && (subModuleId && (!_.isEqual(subModuleJson, {}) && subModuleJson?.allow && location.pathname !== '/app') === false);
                        // eslint-disable-next-line
                        // console.log('ddddddd: ', moduleId, subModuleId, location.pathname, isNotAccessible, permissionObject);    

                        if (isNotAccessible) {
                            return <Navigate
                                to={{
                                    pathname: '/app/access-denied',
                                    state: {from: props.location}
                                }}/>
                        }
                    }
                }

                return <Component {...props} />;
            }

            return <Navigate
                to={{
                    pathname: '/user/login',
                    state: {from: props.location}
                }}/>
        }

        return <Component {...props} />;
    }

    return setComponent(rest);
}

const UserRole = {
    Admin: 7,
    Editor: 1
}

export {ProtectedRoute, UserRole};
