import Storage from './storage';
import common from '../../src/helpers/common';

/**
 * Get country code from localStorage for the selected country for PC Admin.
 *
 * @param isGlobal
 * @returns {string|undefined|string}
 */
export function getCountryCodeFromStorage(isGlobal = false) {
    if (isGlobal) {
        return 'gb'
    }

    if (!Storage.has('country_parameters')) {
        return common.selectedCountry()?.short_name;
        // throw new TypeError(`country_parameters not present in localStorage`)
    } else if (Storage.has('current_selected_country')){
        return Storage.get('current_selected_country')?.countryShortName?.toLowerCase();
    }

    const countryParameters = Storage.get('country_parameters');
    return countryParameters?.country_iso_code?.toLowerCase();
}
